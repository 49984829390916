.select-stamp img {
    max-height: 8em;
    padding: 1em;
}

.select-stamp img.selected {
    border: .2em solid black;
}

.select-stamp img.not-selected {
    border: .2em solid transparent;
    opacity: .5;
}

.select-stamp img.not-selected:hover {
    opacity: 1;
}

.select-stamp {
    background-color: #bbb;
    display: table-row;
    box-shadow: 5px 5px 5px #888;
}

.select-stamp .selector {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 1em;
    padding-top: 1em;
}

.select-stamp h2 {
    padding-top: .5em;
    margin: 0 0 1em 0;
    text-align: center;
}

.image-edit {
    display: table-row;
    height: 100%;
    overflow: visible;
}

.editor {
    display: table;
    width: 100%;
    min-height: 100vh;
}

.controls {
    display: table-row;
    text-align: center;
}

.controls .close {
    font-size: 3em;
    font-weight: bold;
    cursor: pointer;
    float: right;
    margin: .1em;
}

.controls .close:hover {
    color: red;
}

.controls input[type="range"] {
    font-size: 1em;
    margin: 2em 1em 1em 1em;
    width: 30em;
}

.controls input[type="button"] {
    font-size: 1em;
    margin: 2em 1em 1em 1em;
    padding: .3em .5em;
    background-color: #0075ff;
    border: 1px solid gray;
    border-radius: .5em;
    box-shadow: 2px 2px 2px #ccc;
    color: white;
}

.controls input[type="button"]:hover {
    box-shadow: 3px 3px 3px #aaa;
}
.controls input[type="button"]:active {
    box-shadow: none;
}


.select-image {
    margin: 1em;
    text-align: center;
}

.select-image input {
    border: .1em solid #ccc;
    margin: 0 auto;
    padding: .5em;
    border-radius: .5em;
    box-shadow: 3px 3px 3px #ccc;
}

.image-editor {
    display: table-cell;
    position: relative;
}

.image-editor-inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    min-height: 100vh;
}

.image-editor-container {
    margin: auto;
    box-shadow: 5px 5px 5px #888;
    position: relative;
}

.image-editor-container img.image {
    width: 100%;
    height: 100%;
}

.image-editor-container img.stamp {
    position: absolute;
    width: 20%;
}

.wait {
    text-align: center;
    font-size: 2em;
}

.wait span {
    text-align: left;
    display: inline-block;
    width: 3em;
}
